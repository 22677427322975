import { Container, FormGroup, Typography } from '@material-ui/core';
import React from 'react';
import MaterialCheckbox, { useCheckboxStyles } from '../components/Controls/MaterialCheckbox';

const CheckboxStyles = () => {
    const classes = useCheckboxStyles();
    return (
        <Container maxWidth='sm'>
            <div>
                <FormGroup>
                    <MaterialCheckbox disabled={true}>
                        <Typography className={classes.formControlLabel}>I agree these are correct</Typography>
                    </MaterialCheckbox>
                    <MaterialCheckbox>
                        <Typography className={classes.formControlLabel}>I agree these are correct</Typography>
                    </MaterialCheckbox>
                    <MaterialCheckbox defaultChecked={true}>
                        <Typography className={classes.formControlLabel}>I agree these are correct</Typography>
                    </MaterialCheckbox>
                </FormGroup>
            </div>
            <div>
                <FormGroup>
                    <MaterialCheckbox disabled={true}>
                        <Typography className={classes.formControlLabelSmall}>I confirm I have checked the <a href='#test'>statement of insurance</a> and am happy with the details i have provided.</Typography>
                    </MaterialCheckbox>

                    <MaterialCheckbox >
                        <Typography className={classes.formControlLabelSmall}>I confirm I have checked the <a href='#test'>statement of insurance</a> and am happy with the details i have provided.</Typography>
                    </MaterialCheckbox>

                    <MaterialCheckbox defaultChecked={true}>
                        <Typography className={classes.formControlLabelSmall}>I confirm I have checked the <a href='#test'>statement of insurance</a> and am happy with the details i have provided.</Typography>
                    </MaterialCheckbox>
                </FormGroup>
            </div>
        </Container>
    );
};

export default CheckboxStyles;