import { createStyles, FormControlLabel, makeStyles, Theme, Typography } from '@material-ui/core';
import Checkbox, { CheckboxProps } from '@material-ui/core/Checkbox';
import React from 'react';

const useCheckboxStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            margin: theme.spacing(1, 0),
            '&:hover': {
                backgroundColor: 'transparent',
            },
        },
        icon: {
            borderRadius: 3,
            width: 34,
            height: 34,
            boxShadow: `inset 0 0 0 2px ${theme.palette.primary.main}, inset 0 -1px 0 ${theme.palette.primary.main}`,
            backgroundColor: 'transparent',
            '$root.Mui-focusVisible &': {
                outline: '2px auto rgba(19,124,189,.6)',
                outlineOffset: 2,
            },
            'input:hover ~ &': {
                backgroundColor: '#transparent',
            },
            'input:disabled ~ &': {
                boxShadow: 'inset 0 0 0 2px #e3e3e3, inset 0 -1px 0 #e3e3e3',
            },
        },
        checkedIcon: {
            backgroundColor: theme.palette.primary.main,
            borderRadius: 3,
            '&:before': {
                display: 'block',
                width: 34,
                height: 34,
                backgroundImage:
                    "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-5 -5 58 58'%3E%3Cpath" +
                    " fill-rule='evenodd' clip-rule='evenodd' d='M18 32.4L9.6 24L6.8 26.8L18 38L42 14L39.2 11.2L18 32.4Z' fill='%23fff'/%3E%3C/svg%3E\")",
                content: '""',
            },
            'input:hover ~ &': {
                backgroundColor: theme.palette.primary.main,
            },
        },
        formControlLabel: {
            fontSize: '1rem',
            fontFamily: 'Spartan MB SemiBold',
        },
        formControlLabelSmall: {
            fontSize: '0.8125rem',
            lineHeight: '0.8125rem',
            fontFamily: 'Spartan MB',
            '& a': {
                color: theme.palette.text.primary,
            },
        },
    }),
);

const StyledCheckbox = (props: CheckboxProps) => {
    const classes = useCheckboxStyles();
    return (
        <Checkbox
            className={classes.root}
            disableRipple
            color="default"
            checkedIcon={<span className={classes.checkedIcon} />}
            icon={<span className={classes.icon} />}
            inputProps={{ 'aria-label': 'decorative checkbox' }}
            {...props}
        />
    );
};

interface Props {
    disabled?: boolean;
    defaultChecked?: boolean;
    handleChange?: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;
    testId?: string;
    children: JSX.Element;
};

const MaterialCheckbox = ({ disabled, defaultChecked, handleChange, testId, children }: Props) => {
    return (
        <FormControlLabel
            control={
                <StyledCheckbox
                    disabled={disabled}
                    defaultChecked={defaultChecked}
                    onChange={handleChange}
                    data-testid={testId || "material-checkbox"} />
            }
            label={children}
        />
    )
};

type CheckboxLabelProps = {
    useSmall?: boolean;
    children: string;
};

const CheckboxLabel = ({ useSmall, children }: CheckboxLabelProps) => {
    const classes = useCheckboxStyles();

    return (
        <Typography className={useSmall ? classes.formControlLabelSmall : classes.formControlLabel}>{children}</Typography>
    )
};

export default MaterialCheckbox;
export { useCheckboxStyles };
export { CheckboxLabel };
